/**
 * @file standalone/basic-grid.scss
 *
 * \brief Library file - shared styles for basic grid
 *
 */

@import '../theme-bootstrap';

$basic-grid-gutter: 12px;

// basic-grid-wrapper--
// 24 32 48, each minus the 12px default to account for overall padding
$pad-24: 12px;
$pad-32: 24px;
$pad-48: 32px;

.basic-grid {
  @include pie-clearfix;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  // formatter settings
  &-formatter {
    &__header {
      padding-bottom: 1em;
      &:empty {
        padding: 0;
      }
    }
    &__footer {
      width: 100%;
      margin-bottom: 1.5em;
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__disclaimer {
      width: 100%;
    }
    &__text {
      p {
        margin: 0;
      }
    }
    &__trigger {
      cursor: pointer;
      .basic-grid__item & {
        &.button::after {
          content: '\002B';
        }
      }
      .active.basic-grid__item & {
        &.button::after {
          content: '\2013';
        }
      }
    }
  }

  // wrapper padding set via cms, only loads for portrait-up
  // @todo may need to have 2 variations
  @include breakpoint($portrait-up) {
    &-wrapper {
      &--24 {
        padding: 0 $pad-24 0 $pad-24;
      }
      &--32 {
        padding: 0 $pad-32 0 $pad-32;
      }
      &--48 {
        padding: 0 $pad-48 0 $pad-48;
      }
    }
  }

  // grid items
  &__item {
    .basic-grid--small-cols-2 &,
    .basic-grid--small-cols-3 &,
    .basic-grid--small-cols-4 & {
      float: $ldirection;
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    @include breakpoint($portrait-up) {
      float: $ldirection;
      width: 33.333%;
      .basic-grid--portrait-cols-1 & {
        float: none;
        width: auto;
        flex: auto;
      }
      .basic-grid--portrait-cols-2 & {
        width: 50%;
      }
      .basic-grid--portrait-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--portrait-cols-4 & {
        width: 25%;
      }
    }
    @include breakpoint($landscape-up) {
      float: $ldirection;
      width: 25%;
      .basic-grid--landscape-cols-1 & {
        float: none;
        width: auto;
      }
      .basic-grid--landscape-cols-2 & {
        width: 50%;
      }
      .basic-grid--landscape-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--landscape-cols-4 & {
        width: 25%;
      }
    }

    // add border to grid item set via cms
    &--border {
      border-top: 1px solid $color-off-black;
      @include breakpoint($portrait-up) {
        border-top: 0;
        border-#{$ldirection}: 1px solid $color-off-black;
        &:nth-child(3n + 1) {
          border: 0;
        }
      }
      &:first-child {
        border-top: 0;
      }
      @include breakpoint($mobile-only) {
        .basic-grid--small-cols-2 &,
        .basic-grid--small-cols-3 &,
        .basic-grid--small-cols-4 & {
          border: 0;
        }
        .basic-grid--small-cols-2 & {
          &:nth-child(2n + 1) {
            border-#{$ldirection}: 0;
          }
        }
        .basic-grid--small-cols-3 & {
          &:nth-child(3n + 1) {
            border-#{$ldirection}: 0;
          }
        }
        .basic-grid--small-cols-4 & {
          &:nth-child(4n + 1) {
            border-#{$ldirection}: 0;
          }
        }
      }
      @include breakpoint($portrait-only) {
        .basic-grid--portrait-cols-1 & {
          border-#{$ldirection}: 0;
          border-top: 1px solid $color-off-black;
        }
        .basic-grid--portrait-cols-2 &,
        .basic-grid--portrait-cols-3 &,
        .basic-grid--portrait-cols-4 & {
          border-top: 0;
          border-#{$ldirection}: 1px solid $color-off-black;
        }
        .basic-grid--portrait-cols-2 & {
          &:nth-child(2n + 1) {
            border-#{$ldirection}: 0;
          }
        }
        .basic-grid--portrait-cols-3 & {
          &:nth-child(3n + 1) {
            border-#{$ldirection}: 0;
          }
        }
        .basic-grid--portrait-cols-4 & {
          &:nth-child(4n + 1) {
            border-#{$ldirection}: 0;
          }
        }
      }
      @include breakpoint($landscape-only) {
        .basic-grid--landscape-cols-1 & {
          border-#{$ldirection}: 0;
          border-top: 1px solid $color-off-black;
        }
        .basic-grid--landscape-cols-2 &,
        .basic-grid--landscape-cols-3 &,
        .basic-grid--landscape-cols-4 & {
          border-top: 0;
          border-#{$ldirection}: 1px solid $color-off-black;
        }
        .basic-grid--landscape-cols-2 & {
          &:nth-child(2n + 1) {
            border-#{$ldirection}: 0;
          }
        }
        .basic-grid--landscape-cols-3 & {
          &:nth-child(3n + 1) {
            border: 0;
          }
        }
        .basic-grid--landscape-cols-4 & {
          &:nth-child(4n + 1) {
            border-#{$ldirection}: 0;
          }
        }
      }
    }

    // inner padding set via cms, only loads for portrait-up
    // @todo may need to have 2 variations
    @include breakpoint($portrait-up) {
      &-inner {
        height: 100%;
        &--24 {
          padding: 0 $pad-24 0 $pad-24;
        }
        &--32 {
          padding: 0 $pad-32 0 $pad-32;
        }
        &--48 {
          padding: 0 $pad-48 0 $pad-48;
        }
      }
    }
  }

  &.basic-grid--small-cols-2,
  &.basic-grid--small-cols-3,
  &.basic-grid--small-cols-4 {
    .basic-grid-formatter:not([class*='padding-sides-small--']) & {
      @include breakpoint($mobile-only) {
        padding-inline: $basic-grid-gutter / 2;
      }
    }
    .basic-grid__item:not([class*='padding-sides-small--']) {
      @include breakpoint($mobile-only) {
        padding-inline: $basic-grid-gutter/2;
      }
    }
    .basic-grid__item:not([class*='padding-bottom--']) {
      @include breakpoint($mobile-only) {
        padding-bottom: $basic-grid-gutter;
      }
    }
  }
}

.salon-grids {
  .salon-grid {
    display: flex;
    flex-direction: column;
  }
  img {
    margin-bottom: 8px;
  }
  .basic-btn {
    align-self: center;
    margin-top: auto;
  }
}
